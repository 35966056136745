import React from "react";
import _ from "lodash";
import loadable from "@loadable/component";

import { htmlToReact } from "../utils";
import ActionLink from "./ActionLink";
// import MapSection from './Map' // import the map here

const MapComponent = loadable(() => import("./Map"));

export default class Footer extends React.Component {
  render() {
    return (
      <footer id="colophon" className="site-footer outer">
        <div className="map-container">
          <p>
            Ne găsești aproape de centru, la adresa Bulevardul Carol I nr. 59,
            București.
          </p>
          <MapComponent /> {/* include it here */}
        </div>
        <div className="inner">
          <div className="site-footer-inside">
            <div className="site-info">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.footer.content",
                null
              ) && (
                <span className="copyright">
                  {htmlToReact(
                    _.get(
                      this.props,
                      "pageContext.site.siteMetadata.footer.content",
                      null
                    )
                  )}
                </span>
              )}
              {_.map(
                _.get(
                  this.props,
                  "pageContext.site.siteMetadata.footer.links",
                  null
                ),
                (action, action_idx) => (
                  <ActionLink
                    key={action_idx}
                    {...this.props}
                    action={action}
                  />
                )
              )}
            </div>
            {_.get(
              this.props,
              "pageContext.site.siteMetadata.footer.has_social",
              null
            ) && (
              <div className="social-links">
                {_.map(
                  _.get(
                    this.props,
                    "pageContext.site.siteMetadata.footer.social_links",
                    null
                  ),
                  (action, action_idx) => (
                    <ActionLink
                      key={action_idx}
                      {...this.props}
                      action={action}
                    />
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }
}
