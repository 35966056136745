import React from 'react';
import { Link as GatsbyLink } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export default function Link({ children, to, activeClassName, partiallyActive, ...other }) {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to);
    const isTel = to.includes('tel');
    // Use Gatsby Link for internal links, and <a> for others
    if (internal && !isTel) {
        return (
            <GatsbyLink
                to={to}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                {...other}
            >
                {children}
            </GatsbyLink>
        )
    }
    if (isTel){
        const newTo =to.replace('/','');
       return (
       <a href={newTo} {...other}>
            {children}
        </a>
        )
    }
    return (
        <a href={to} {...other}>
            {children}
        </a>
    )
}
